.footer{
  background: radial-gradient(109.46% 66.93% at 78.12% 39.27%, #2C2F28 0%, #3F4B45 100%);
  padding-top: 5rem;
  padding-bottom: 0;
  *{
    font-weight: 400;
    line-height: 150%;
  }
  li{
    list-style: none !important;
  }
  a{
    color: $gray-100;
  }
  &_wrapper{
    @media(max-width:1199px){
      flex-wrap:wrap;
    }
    @media(max-width:767px){
      flex-direction: column;
    }
  }
  &_info{
    flex: 0 0 27.575rem;
    max-width: 27.575rem;
    padding-right: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @media(max-width:767px){
      flex: unset;
      max-width: unset;
    }
    &__phone{
      padding-bottom: 0.5rem;
      display: flex;
      flex-direction: column;
      &-link{
        padding-bottom: 1rem;
        font-size: 1.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 115%;
        color: $white !important;
      }
    }
    &__desc{
      padding-bottom: 3rem;
      font-size: $font-size-xs;
    }
    &__pay-system{
      display: flex;
      align-items: flex-start;
      flex-grow: 1;
      padding-bottom: 3rem;
      svg{
        &+svg{
          margin-left: 1rem;
        }
        &.mastercard{
          width: 3.25rem;
          height: 2.5rem;
        }
        &.visa{
          width: 4.625rem;
          height: 1.5rem;
        }
      }
    }
    &__copyright{
      font-size: $font-size-xs;
    }
  }
  &_menu{
    display: flex;
    margin: 0 -2.5rem;
    justify-content: space-between;
    flex-grow: 1;
    @media(max-width:1199px){
      margin: 0 -1rem;
    }
    @media(max-width:767px){
      margin-top: 3rem;
    }
    &__catalog{
      padding: 0 2.5rem;
      @media(max-width:767px){
        padding: 0 1rem;
        flex: 0 0 33%;
        max-width: 33%;
        border-right: 1px solid rgba(255,255,255,0.05);
        &:last-child{
          border: none;
        }
      }
      &>a{
        color: $white !important;
        font-size: $font-size-md;
      }
      a{
        padding-bottom: 1.5rem;
        color: $white;
        line-height: 115%;
        display: block;
      }
      .sub-menu{
        padding: 0;
        margin: 0;
      }
    }
    &__link{
      a{
        padding-bottom: 1.25rem;
        color: $gray-100;
        font-size: $font-size-sm;
      }
    }
  }
  &_social{
    flex: 0 0 30.875rem;
    max-width: 30.875rem;
    padding-left: 4.5rem;
    @media(max-width:1199px){
      flex: 0 0 100%;
      max-width: 100%;
      padding: 2rem 0 0 0;
    }
    @media(max-width:575px){
      padding: 1.5rem 0;
    }
    &__title{
      font-size: $font-size-lg;
      margin-bottom: 1.5rem;
      color: $white;
      line-height: 115%
    }
    &__wrapper{
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    &__item{
      flex: 0 0 3rem;
      max-width: 3rem;
      height: 3rem;
      border: 1px solid $gray-100;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.25rem;
      svg{
        width: 1.25rem;
        height: 1.25rem;
      }
    }
  }
  &-bottom{
    margin-top: 5rem;
    background-color: rgba(67, 95, 197, 0.05);
    @media(max-width:575px){
      margin-top: 2.5rem;
    }
    .nav-link{
      padding: 0.7rem 2.5rem;
      text-decoration: underline !important;
      font-size: $font-size-xs;
    }
  }
}