.content_page{
  section{
    @media(max-width:767px){
      padding-top: 5rem !important;
      &.mb-10r{
        margin-bottom: 5rem !important;
      }
    }
    @media(max-width:575px){
      .two-col-screen{
        &__text{
          padding: 0 !important;
        }
        &__img{
          height: unset;
          margin-bottom: 0;
          img{
            object-fit: contain;
          }
        }
      }
      &:first-child{
        padding-top: 8rem !important;
      }
    }
  }
  .video{
    padding-top: 8.625rem;
    &_container{
      margin: 0;
    }
    &_content{
      width: 32.75rem;
      position: absolute;
      right: 6.31rem;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media(max-width:1199px){
        right: 0;
      }
      @media(max-width:575px){
        right: unset;
        left: 0;
        padding: 1.5rem 1rem ;
        width: 100%;
        border-radius: 1.5rem;
        background: rgba(0, 0, 0, 0.25);
        .h4{
          font-size: 2.5rem;
        }
      }
    }
  }
  .business_model{
    padding: 5rem 0;
    background-color: $gray-white;
    .two-col-screen{
      &__text{
        @media(min-width:1200px){
          padding-right: 9.95rem;
        }
      }
      &__img{
        max-width: 52.5rem;
        height: unset;
        @media(max-width:1199px){
          width: 100%;
          max-width: 57rem;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
  .structure{
    &_blocks{
      display: flex;
      align-items: stretch;
      flex-wrap: wrap;
      margin: 0 -1rem;
      &__item{
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 1rem;
        flex-direction: column;
        align-items: stretch;
        display: flex;
        @media(max-width:991px){
          flex: 0 0 100%;
          max-width: 100%;
          &:first-child{
            padding-bottom: 1rem;
          }
        }
      }
    }
    &_block{
      margin-top: 2rem;
      border-radius: 1.25rem;
      background: $gray-white;
      padding: 2.1875rem 2.5rem;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      .h6{
        margin-bottom: 2rem;
      }
      img{
        width: 100%;
        margin-top: auto;
        @media(max-width:991px){
          margin-top: -6rem;
        }
        @media(max-width:575px){
          margin-top: -3rem;
        }
      }
    }
  }
  .rewards{
    padding: 5rem 0;
    background-color: $gray-white;
    .white-bg-block{
      @media(min-width:992px){
        padding-right: 5rem;
      }
    }
    .two-col-screen{
      &__text{
        padding-top: 0;
        padding-bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @media(min-width:1200px){
          padding: 0 11rem 0 5rem;
        }
      }
      &__img{
        max-width: 53rem;
        height: unset;
        @media(max-width:1199px){
          width: 100%;
          max-width: 57rem;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
  .refferal_bonus{
    padding: 6.25rem 0;
    background-color: $gray-white;
    .container{
      max-width: 100rem;
    }
    .two-col-screen{
      &__text{
        padding-top: 0;
        padding-bottom: 3rem;
        @media(min-width:1200px){
          padding: 0 3rem 0 0;
        }
      }
      &__img{
        overflow: unset;
        height: unset;
        flex: unset;
        max-width: unset;
        width: 100%;
        @media(max-width:1199px){
          max-width: 57rem;
          margin-left: auto;
          margin-right: auto;
          img{
            object-fit: contain;
          }
        }
        @media(max-width:575px){
          margin-top: 2rem;
        }
      }
    }
  }
  .megaphone{
    .video_container{
      &>div{
        @media(min-width:1200px){
          height: 50rem;
        }
      }
    }
  }
  .team_bonus{
    background-color: $gray-white;
    padding-top: 1.78rem;
    .container{
      max-width: 83.5rem;
    }
    .white-bg-block{
      padding: 1.5rem 2rem;
    }
    .checkbox_list{
      font-size: $font-size-md;
    }
    .two-col-screen{
      &__text{
        padding: 0 7.5rem 0 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @media(max-width:991px){
          padding: 0 1.5rem;
        }
      }
      &__img{
        overflow: unset;
        height: unset;
        flex: 0 0 63.3rem;
        max-width: 63.3rem;
        margin-left: -14rem;
        @media(max-width:991px){
          margin: 0 auto;
        }
      }
    }
  }
  .matching_bonus{
    .container{
      max-width: 100rem;
    }
    .gray-bg-block{
      width: 56rem;
      @media(max-width:991px){
        width: unset;
      }
    }
    .white-bg-block{
      padding: 1.5rem;
    }
    .checkbox_list{
      font-size: $font-size-md;
    }
    .two-col-screen{
      &__text{
        padding: 0 0 0 10rem;
        @media(max-width:1199px){
          padding: 0 1.5rem 3rem;
        }

      }
      &__img{
        overflow: unset;
        height: unset;
        flex: 0 0 50%;
        max-width: 50%;
        display: flex;
        align-items: center;
        @media(max-width:991px){
          margin: 0 auto;
        }
        @media(max-width:575px){
          flex: 0 0 100%;
          max-width: 100%;
          margin-top: 2rem;
        }
        img{
          object-fit: contain;
          position: relative;
          z-index: 1;
          &.bg{
            top: 0;
            height: 100%;
            left: -1rem;
            width: 72%;
            z-index: 0;
            @media(max-width:991px){
              top: unset;
              left: 15%;
            }
            @media(max-width:575px){
              top: 14rem;
              left: 0;
              height: unset;
              width: 120%;
            }
          }
        }
      }
    }
  }
  .get_new_rank{
    &-tile_block{
      width: 50rem;
      margin: 0 auto 4rem;
      @media(max-width:575px){
        width: 100%;
      }
      .h4, p{
        text-align: center;
      }
    }
  }
  .referral_program{
    &__block{
      position: absolute;
      left: 2.25rem;
      bottom: 1.5rem;
      background-color: $white;
      padding: 1.5rem 2.5rem;
      border-radius: 1rem;
      width: 47.5rem;
      max-width: 100%;
      @media(max-width:1199px){
        position: static;
        padding: 2rem 0 3rem;
      }
      .h5{
        margin-bottom: 2rem;
      }
    }
    &__items{
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      &>div{
        border-left: 1px solid $border;
        padding: 0 3rem;
        @media(max-width: 575px){
          padding: 0 1.5rem;
        }
        &:first-child{
          border: none;
          padding-left: 0;
        }
        &:last-child{
          padding-right: 0;
        }
      }
    }
  }
  .packages_head{
    @media(max-width:767px){
      padding-top: 7rem!important;
    }
    &-wrapper{
      padding: 0 6.25rem;
      background-color: $gray-white;
      border-radius: 2rem;
      @media(max-width:1199px){
        padding-right: 0;
      }
      @media(max-width:991px){
        padding: 4rem 4rem 0;
      }
      @media(max-width:575px){
        padding: 2rem 2rem 0;
      }
    }
    .two-col-screen{
      &__text{
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 48rem;
        @media(max-width:767px){
          text-align: center;
          margin: 0 auto 2rem;
          align-items: center;
        }
        .h4,p{
          max-width: 44.6875rem;
          @media(max-width:767px){
            max-width: unset;
          }
        }
      }
      &__img{
        @media(max-width:1199px){
          flex: unset;
          margin-bottom: 0;
        }
      }
    }
  }
  .easy_fast{
    padding: 5rem 0;
    background-color: $gray-white;

    .container{
      max-width: 103rem;
    }
    .two-col-screen__text{
      padding-left: 4rem;
      position: relative;
      z-index: 1;
      @media(max-width:767px){
        padding-left: 0;
        .white-bg-block{
          background-color: transparent;
        }
      }
    }
    @media(max-width:1199px){
      .bg{
        left: -7rem !important;
        z-index: 0;
      }
    }
    &.matching_bonus .two-col-screen__img{
      @media(max-width:991px){

        max-width: 75%;
      }
      @media(max-width:767px){
        max-width: 100%;
      }
    }
  }
  .get_new_rank_pack{
    .wrapper_grid_table .grid_table{
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    .grid_table-cell{
      text-align: center;
      font-size: 1rem;
      &:nth-child(n+4){
        text-align: left;
      }
    }
    .grid_table-header{
      text-align: center;
    }
    .checkbox_list{
      margin: 0 auto;
      width: max-content;
    }
  }
}
