.products{
  &_attributes{
    padding: 2rem 0 1rem;
    border-top: 1px solid $gray-100;
    border-bottom: 1px solid $gray-100;
    margin-bottom: 2.5rem;
    &__wrapper{
      margin:0 -1.5rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      //grid-template-columns: max-content max-content 1fr;
      &>div{
        padding: 0 1.5rem;
        width: max-content;
        margin-bottom: 1rem;
      }
    }
  }
  &_buy-widget{
    padding: 4.4rem 0;
    .counter{
      padding-left: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      button{
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 100%;
        padding: 0;
        background-color: #F6F7F9;
        border: none;
        font-size: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
      }
      &__input{
        width: 3.5rem;
        font-size: 1.5rem;
        border: none;
        padding: 0 1rem;
      }
    }
    @media(max-width:575px){
      flex-direction: column;
      align-items: flex-start !important;
    }
  }
  .two-col-screen__wrapper{
    align-items: flex-start;
    margin-bottom: 10rem;
  }
  .two-col-screen__text{
    padding:2.5rem;
    margin-right: 1.44rem;
    margin-bottom: 2rem;
    border-radius: 2rem;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.06);
    @media(max-width:1199px){
      margin-right: 0;
    }
    @media(max-width:575px){
      padding: 1.75rem;
    }
  }
  .two-col-screen__img{
    height: 50rem;
    border-radius: 2rem;
    @media(max-width:1199px){
      flex: unset;
      max-width: unset;
      width: 100%;
    }
  }
  .shared{
    &-icons{
      margin: 0 -0.8rem;
      a{
        padding: 0 0.8rem;
        svg{
          width: 1.8rem;
          height: 1.8rem;
        }
      }
    }
  }
  &-description{
    background: radial-gradient(109.46% 66.93% at 78.12% 39.27%, #2C2F28 0%, #3F4B45 100%);
    padding: 7rem 0;
    @media(max-width:575px){
      padding-top: 3rem;
    }
    .butterfly_layer {
      .right{
        top: 2.37rem;
        right: 4.7rem;
        width: 10.39rem;
        height: 9.39rem;
      }
      .top{
        top: 1rem;
        left: 1.7rem;
        width: 4.2rem;
        height: 4rem;
      }
      .bottom{
        width: 7.87rem;
        height: 6.75rem;
        left: 3.13rem;
        bottom: 2.7rem;
      }
    }
    &_bg{
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      img{
        width: 100%;
        min-height: 100%;
        object-fit: cover;
      }
    }
    &_content{
      width: 100%;
      max-width: 75.56rem;
      margin: 0 auto;
      display: flex;
      align-items: stretch;
      @media(max-width:575px){
        flex-direction: column;
      }
      &__img{
        width: 35.2595rem;
        height: 35.2595rem;
        border-radius: 100%;
        border: 1px solid rgba(255, 255, 255, 0.10);
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        &::before{
          content: '';
          position: absolute;
          border-radius: 100%;
          border: 1px solid rgba(255, 255, 255, 0.04);
          width: 43.5625rem;
          height: 43.5625rem;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
        &::after{
          content: '';
          position: absolute;
          border-radius: 100%;
          border: 1px solid rgba(255, 255, 255, 0.04);
          width: 51.6875rem;
          height: 51.6875rem;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
        img{
          width: 24.1rem;
          height: 24.1rem;
          border-radius: 100%;
          object-fit: cover;
        }
      }
      &__wrapper{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: -8.375rem;
        padding: 1.8rem 0;
        @media(max-width:575px){
          margin-left: 0;
          padding: 0;
        }
      }
      &__item{
        padding-left: 7.4rem;
        position: relative;
        @media(max-width:575px){
          margin-bottom: 1.5rem;
          padding-left: 5rem;
          &>div:first-child{
            padding-bottom: 0.25rem;
          }
        }
        &:nth-child(2){
          margin-left: 7.15rem;
          @media(max-width:575px){
            margin-left: 0;
          }
        }
        &::before{
          content: '';
          width: 2.27rem;
          height: 2.27rem;
          border-radius: 100%;
          background-color: #257535;
          border: 0.57rem solid $white;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}