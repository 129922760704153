.contact_page{
    .two-col-screen{
        padding-bottom: 9.25rem;
        @media(max-width:1199px){
            flex-direction: column-reverse;
        }
        &__text{
            padding: 2.88rem 6.6rem 0 8.91rem;
        }
        &__img{
            height: unset;
            border-radius: 1rem;
            width: 100%;
            @media(max-width:1199px){
                margin-top: 3rem;
            }
            button{
                img{
                    width: 1.125rem;
                    height: 1.125rem;
                    min-height: unset;
                    object-fit: unset;
                }
            }
        }
    }
    .form-group{
        padding: 0.5rem 1rem;
        border-radius: 0.75rem;
        border: 1px solid rgba(143, 143, 171, 0.60);
        margin-bottom: 0.75rem;
        .form-control{
            border: none;
            padding: 0;
        }
        textarea.form-control{
            height: 5rem;
            min-height: unset;
        }
    }
    &-gray-block{
        margin: 1.7rem 0;
        padding: 1rem;
        border-radius: 0.875rem;
        background: rgba(238, 240, 250, 0.40);
        font-size: $font-size-xs;
        color: #111;
        display: flex;
        align-items: center;
        svg{
            width: 1.875rem;
            height: 1.875rem;
            margin-right: 0.75rem;
        }
        a{
            color: #1361BC;
            text-decoration-line: underline;
        }
    }
}