//.head_page{
  section{
    padding-top: 10rem;
    @media(max-width:767px){
      padding-top: 7rem;
    }
  }
  svg{fill: currentColor;}
  .first_screen{
    padding: 10.19rem 0 5rem;
    background: radial-gradient(109.46% 66.93% at 78.12% 39.27%, #2C2F28 0%, #3F4B45 100%);
    .mobile_show{
     @media(max-width:767px){
       display: flex !important;
       justify-content: center;
       margin-bottom: 3rem;
     }
    }
    &__content{
      display: flex;
      @media(max-width:767px){
        flex-direction: column;
        width: 100%;
        img{
          width: 100% !important;
          height: 26.45rem !important;
          object-fit: contain;
          margin-bottom: 2.5rem !important;
        }
      }
      @media(max-width:1199px){
        flex-wrap: wrap;
      }
      .content_desc{
        flex: 0 0 29.375rem;
        max-width: 29.375rem;
        @media(max-width:1199px){
          order:1;
          flex: 0 0 calc(50% - 1rem);
          max-width: calc(50% - 1rem);
          margin-right: 2rem;
        }
        @media(max-width:767px){
          order: unset;
          margin: 0 auto 3rem;
          flex: 0 0 60%;
          max-width: 60%;
          padding: 0 1rem;
        }
        @media(max-width:575px){
          flex: 0 0 89%;
          max-width: 89%;
          .transparent-gray-block{
            text-align: center;
          }
        }
        img{
          width: 22.8rem;
          margin-bottom: 3.06rem;
          display: block;
          @media(max-width:1199px){
            margin: 0 auto 3.06rem;
          }
        }
      }
      .content_title{
        padding: 3.18rem 1.25rem 0 4.5rem;
        &-text, &-title{
          text-align: center;
        }
        @media(max-width:1199px){
          order:0;
          flex: 0 0 100%;
          max-width: 100%;
          display: flex;
          align-items: center;
          margin-bottom: 2rem;
          &>div{
            flex:0 0 50%;
            max-width: 50%;
          }
          &-text, &-title{
            text-align: left;
          }
        }
        @media(max-width:767px){
          order: unset;
          margin: 0 auto 3rem;
          flex: 0 0 60%;
          max-width: 60%;
          flex-direction: column-reverse;
          padding: 0 2rem;
          justify-content: flex-end;
          &>div{
            flex: unset;
            max-width: unset;
          }
          h1{
            display: none;
          }
        }
        @media(max-width:575px){
          flex: 0 0 89%;
          max-width: 89%;
          .content_title-text{
            text-align: center;
          }
        }
        .text-xl{
          max-width: 43rem;
          @media(max-width:1199px){
            max-width: unset;
          }
        }
        img{
          width: 32.6875rem;
          margin: 5.5rem auto 0;
          display: block;
          @media(max-width:1199px){
            width: 24.12rem;
            margin: 0 auto;
          }
        }
      }
      .content_status{
        flex: 0 0 33.5rem;
        max-width: 33.5rem;
        color: $palemint;
        @media(max-width:1199px){
          order:1;
          flex: 0 0 calc(50% - 1rem);
          max-width: calc(50% - 1rem);
        }
        @media(max-width:767px){
          order: unset;
          margin: 0 auto;
          flex: 0 0 60%;
          max-width: 60%;
        }
        @media(max-width:575px){
          order: unset;
          margin: 0 auto;
          flex: 0 0 89%;
          max-width: 89%;
          .content_status{
            &__item, &__text{
              text-align: center;
            }
          }
        }
        &__item{
          font-size: $font-size-lg;
          line-height: 140%;
          max-width: 10.43rem;
          @media(max-width:767px){
            flex: 0 0 33%;
            max-width: 33%;
          }
         &-title{
           padding-bottom: 0.25rem;
           font-size: $h6-font-size;
           line-height: 140%;
           svg{
             width: 3.12rem;
             height: 2.8125rem;
           }
         }
        }
        &__text{
          padding: 1.5rem 0 3rem;
        }
        img{
          width: 24.12rem;
          margin-bottom: 1.25rem;
          margin-left: auto;
          display: block;
          @media(max-width:1199px){
            margin: 0 auto 1.25rem;
          }
        }
        .line-in-status{
          width: 100%;
          height: 0.375rem;
          bottom: 0;
          left: 0;
        }
      }
    }
  }
  .two-col-screen{
    &__wrapper{
      border-radius: 2rem;
      background: #F6F7F9;
      display: flex;
      align-items: center;
      @media(max-width:1199px){
        flex-direction: column;
      }
      &.without_bg{
        background: none;
      }
    }
    &__text{
      padding: 8.81rem 5.62rem;
      @media(max-width:1199px){
        padding: 5rem 2rem 2rem;
      }
    }
    &__img{
      flex: 0 0 57.5rem;
      max-width: 57.5rem;
      height: 42.5625rem;
      overflow: hidden;
      border-radius: 3.125rem;
      @media(max-width:1199px){
        flex: 0 0 calc(100% - 4rem);
        max-width: calc(100% - 4rem);
        margin-bottom: 2rem;
        border-radius: 3.5rem;
      }
      img{
        width: 100%;
        min-height: 100%;
        object-fit: cover;
      }
    }
    &.columns-swapped{
      .two-col-screen__wrapper{
        flex-direction: row-reverse;
        @media(max-width:1199px){
          flex-direction: column;
          .two-col-screen{
            &__text{
              padding-top: 0;
              padding-left: 0;
              padding-right: 0;
            }
            &__img{
              flex: 0 0 100%;
              max-width: 100%;
            }
          }
        }
      }
    }
  }
  .more_satisfied{
    &-title{
      width: 55.125rem;
      max-width: 100%;
      text-align: center;
      margin: 0 auto;
      .sub_title-big{
        margin-left: 2.5rem;
        margin-right: 2.5rem;
      }
    }
  }
  .super_line{
    background-image: url('../img/head_page/line_super.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 5rem 0;
    .h2{
      padding-bottom: 3.25rem;
    }
    .btn,.super_line__item_bg{
      opacity: 0;
      pointer-events: none;
      transition: .3s;
    }
    .slick-current, .slick-slide .super_line__item:hover{
      .btn,.super_line__item_bg{
        opacity: 1;
        pointer-events: unset;
      }
    }
    &__slider{
      width: 100%;
      max-width: 99.25rem;
      margin: 0 auto;
      @media(max-width:767px){
        max-width: unset;
        width: 85%;
      }
    }
    &__item{
      padding: 3rem 3.5rem;
      border-radius: 18.75rem;
      position: relative;
      width: 26.25rem;

      &_bg{
        position: absolute;
        border-radius: 18.75rem;
        background: linear-gradient(82deg, rgba(255, 255, 255, 0.20) 37.97%, rgba(255, 255, 255, 0.00) 109.51%);
        backdrop-filter: blur(5px);
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 0;
        .img_leaves{
          width: 20.3125rem;
          height: 18.75rem;
          margin-top: 3.44rem;
          margin-left: 1.72rem;
        }
      }
      &-content{
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        height: 36.375rem;
      }
      &-img{
        margin:0 auto 1.5rem;
        width: 17.5rem;
        height: 21.25rem;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
          width: 100% !important;
          height: 100% !important;
          object-fit: contain;
        }
      }
      &-desc{
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        .desc_title{
          font-size: 2rem;
          font-weight: 700;
          line-height: 115%; /* 2.3rem */
          letter-spacing: 0.08rem;
        }
        .desc_text{
          flex-grow: 1;
        }
        .desc_price{
          margin-bottom: 1.5rem;
        }
      }

    }
    .slick-slide{
      display: flex;
      justify-content: center;
    }
    .slick-prev{
      left: 0;
      @media(max-width:767px){
        left: -4rem;
      }
      @media(max-width:576px){
        left: -1.5rem;
      }
       &::before{
        background-image: url(../img/slide_arrow.svg);
      }
    }
    .slick-next{
      right: 0;
      @media(max-width:767px){
        right: -4rem;
      }
      @media(max-width:576px){
        right: -1.5rem;
      }
       &::before{
        background-image: url(../img/slide_arrow.svg);
      }
    }
    .slick-prev,.slick-next{
      background-color: transparent;
      border: none;
      width: 3rem;
      height: 3rem;
      bottom: unset;
      top: 15.06rem;
      &::before{
        width: 100%;
        height: 100%;
      }
    }
    .slick-initialized{
      margin-bottom: 0;
      padding-bottom: 3.25rem;
    }
    .slick-dots {
      bottom: 0;
      li {
        width: unset;
        height: unset;
        margin: 0 0.375rem;

        button {
          padding: 0;
          width: 0.75rem;
          height: 0.75rem;
          border-radius: 0.625rem;
          background: rgba(255, 255, 255, 0.50);
          transition: .3s;
          &::before{
            display: none;
          }
        }

        &.slick-active {
          button {
            width: 1.5rem;
            background: $white;
          }
        }
      }
    }
  }
  .order{
    background-image: url(../img/head_page/order_bg.webp);
    padding: 5rem 0;
    .img_leaves{
      width: 24rem;
      margin-top: -14rem;
      @media(max-width:767px){
        width: 20rem;
        margin-top: -6rem;
      }
    }
    &_wrapper{
      height: 30rem;
    }
    &_img{
      width: 25rem;
      @media(max-width:575px){
        width: 100%;
      }
    }
    @media(max-width:575px){
      h3.h3{
        font-size: 3rem !important;
      }
    }
  }
  .video{
    .wrapper_video-blocks{
      overflow: auto;
      width: 100%;
    }
    .block{
      padding: 0 1.88rem;
      overflow: hidden;
      @media(max-width:767px){
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 1.5rem;
      }
      .title{
        margin-bottom: 4.2rem;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: 0.02rem;
        @media(max-width:1199px){
          margin-bottom: 2rem;
          height: 4.25rem;
        }
      }
    }
    &_container{
      width: 100%;
      border-radius: 2rem;
      margin-top: 3.5rem;
      overflow: hidden;
    }
  }

  .reviews{
    background: radial-gradient(109.46% 66.93% at 78.12% 39.27%, #2C2F28 0%, #3F4B45 100%);
    padding: 7.5rem;
    @media(max-width:767px){
      padding-left: 0;
      padding-right: 0;
    }
    .slick-dots {
      display: flex;
      justify-content: center;
      @media(max-width:1199px){
        bottom: -4rem;
      }
      li{
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 100%;
        border: 1px solid $gray-100;
        display: flex;
        align-items: center;
        button{
          font-size: 1rem !important;
          color: $gray-100;
          width: 100% !important;
          min-height: 100% !important;
          padding: 0 !important;
          &::before{
            display: none !important;
          }
        }
        &.slick-active{
          background-color: $gray-100;
          button{color: $brown;}
        }
      }
    }
    &_wrapper{
      width: 100%;
      max-width: 90.42rem;
      margin: 0 auto;
    }
    &_item{
      display: flex;
      align-items: center;
      padding: 0 0.375rem;
      @media(max-width:575px){
        flex-direction: column;
      }
      &__img{
        flex: 0 0 27.84rem;
        max-width: 27.84rem;
        @media(max-width:575px){
          flex: 0 0 100%;
          max-width: 100%;
        }
        .img_border{
          //border: 0.65rem solid $white;
          transform: rotateZ(-1.5deg);
          position: relative;
          img{
            width: 100%;
          }
          //&::after{
          //  content: '';
          //  position: absolute;
          //  top: -1.5rem;
          //  left: 20%;
          //  width: 6.355rem;
          //  height: 2.572rem;
          //  background-image: url(../img/head_page/plaster.webp);
          //  background-size: cover;
          //}
        }
      }
      &-wrapper{
        padding-left: 4rem;
        @media(max-width:575px){
          padding-left: 0;
          padding-top: 2rem;
        }
      }
      &__text{
        @media(max-width:575px){
          text-align: center;
        }
      }
      &__desc{
        line-height: 140%;
        @media(max-width:575px){
          text-align: center;
        }
      }
    }
  }
  .faq{
    &_item{
      border-top: 1px solid $gray-200;
      padding: 2.5rem 2rem;
      display: flex;
      align-items: center;
      @media(max-width:575px){
        flex-direction: column;
        &__title{
          max-width: unset !important;
          padding-right: 0;
          padding-bottom: 1.25rem;
        }
      }
      &:last-child{
        border-bottom: 1px solid $gray-200;
      }
      &__title{
        flex-grow:1;
        font-size: $font-size-xl;
        line-height: 140%;
        letter-spacing: 0.015rem;
        font-weight: 500;
        @media(max-width:1199px){
          max-width: 20rem;
          padding-right: 1.5rem;
        };
        @media(max-width:575px){
          max-width: 17rem;
        }
      }
      &__text{
        width: 100%;
        max-width: 61.25rem;
      }
    }
  }

  .illustrator {
    &_wrapper {
      display: flex;
      border-radius: 2rem;
      overflow: hidden;
      &>*{
        flex: 0 0 33.3%;
        max-width: 33.3%;
        border-left: 0.5rem solid $white;
        border-right: 0.5rem solid $white;
        &:first-child, &:last-child {
          border: none;
        }
        img{
          width: 100%;
          object-fit: cover;
          min-height: 100%;
        }
      }
    }
  }
//}