header{
  &::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: radial-gradient(109.46% 66.93% at 78.12% 39.27%, #2C2F28 0%, #3F4B45 100%);
    transition: 0.5s;
  }
  .hamRotate{
    width: 3.25rem;
    display: none;
    @media(max-width:767px){
      display: block;
    }
  }
  .ham {
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    transition: transform 400ms;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .hamRotate.active {
    transform: rotate(45deg);
  }
  .hamRotate180.active {
    transform: rotate(180deg);
  }
  .line {
    fill:none;
    transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
    stroke:$white;
    stroke-width:5.5;
    stroke-linecap:round;
  }
  .ham8 .top {
    stroke-dasharray: 40 160;
  }
  .ham8 .middle {
    stroke-dasharray: 40 142;
    transform-origin: 50%;
    transition: transform 400ms;
  }
  .ham8 .bottom {
    stroke-dasharray: 40 85;
    transform-origin: 50%;
    transition: transform 400ms, stroke-dashoffset 400ms;
  }
  .ham8.active .top {
    stroke-dashoffset: -64px;
  }
  .ham8.active .middle {
    transform: rotate(90deg);
  }
  .ham8.active .bottom {
    stroke-dashoffset: -64px;
  }
  &.fixed{
    &::before{
      opacity: 1;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.12);
    }
  }
  .header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 6.125rem;
    @media(max-width:767px){
      position: relative;
    }
    a{
      color: $white !important;
      font-size: $font-size-md !important;
      line-height: 115%;
      @media(max-width:767px){
        font-size: $font-size-big !important;;
      }
    }
    &_logo{
      z-index: 1;
      img{
        width: 13.5rem;
        height: 3.125rem;
        @media(max-width:767px){
          width: 20rem;
          height: 6rem;
        }
      }
    }
    &_menu{
      .nav{
        margin: 0 -1.25rem;
        @media(max-width:767px){
          position: absolute;
          left: 0;
          display: flex;
          flex-direction: column;
          top: 0;
          padding-top: 6.125rem;
          width: 480px;
          height: 100vh;
          overflow: hidden;
          transform: translateX(-101%);
          transition: .3s;
          &.open{
            transform: translateX(-0.25rem);
          }
          &::before{
            content: '';
            position: absolute;
            background-color: rgba(63, 75, 69, 0.65);
            left: 0;
            top: 0;
            width: 480px;
            height:100vh;
            box-shadow: 6px 2px 15px -10px $black;
            backdrop-filter: blur(10px);
            @media(max-width:575px){
              width: calc(100% + 3rem);
            }
          }
        }
        @media(max-width:575px){
          width: calc(100% + 3rem);
        }
        &-item{
          padding: 0 1.25rem;
          @media(max-width:767px){
            padding: 1.5rem;
            width: max-content;
          }
          @media(max-width:575px){
            margin: 0 auto;
            a{
              font-size: $font-size-big !important;
            }
          }
          &.active, &:hover{
            .nav-link{
              &::after{
                width: 100%;
              }
            }
          }
        }
        &-link{
          padding: 0;
          position: relative;
          &::after{
            content: '';
            position: absolute;
            right: 0;
            bottom: -0.25rem;
            height: 1px;
            width: 0%;
            background-color: $white;
            transition: .3s;
          }
        }
      }
    }
    &_links{
      display: flex;
      align-items: center;
    }
    &_langs{

    }
    &_auth{
      margin: 0 -0.5rem;
      z-index: 1;
      @media(max-width:767px){
        margin: 1rem 0 0 1rem;
      }
      @media(max-width:575px){
        margin-top: 1.5rem;
        justify-content: center;
      }
      &__links{
        padding: 0 1rem;
        &.before_board{
          position: relative;
          padding-left: calc(0.5rem + 1px);
          &::before{
            content: '';
            position: absolute;
            height: 1rem;
            width: 1px;
            background-color: $white;
            left: -1px;
            top: calc(50% - 0.5rem);
            @media(max-width:767px){
              top: 0;
              min-height: 100%;
            }
          }
        }
      }
      @media(max-width:575px){
        &>a {
          font-size: $font-size-big !important;
        }
      }
    }
  }

  &.white_header{
    .line {
      stroke:$black;
    }
   .header_menu .nav::before{
     background-color: rgba(255,255,255,0.85);
   }
   .header_menu .nav-item.active a, .header_menu li.nav-item:hover a {
     color: $indigo !important;
     &::after{
       background-color: $indigo !important;
     }
   }
   .header_auth__links.before_board::before{
     background-color: $black;
   }
    a{
      color: $black !important;
    }
    &::before{
      background: rgba(255,255,255,0.85);
      backdrop-filter: blur(10px);
    }
  }
}
