.store{
  @media(max-width:1199px){
    .wrapper_video-blocks{
      padding-top: 2rem;
    }
  }
  @media(max-width:767px){
    .wrapper_video-blocks{
      flex-direction: column;
      .block .title{
        height: unset;
      }
    }
  }
  .store-video-desc{
    width: 100%;
    max-width: 41.375rem;
    line-height: 140%;
    letter-spacing: 0.015rem;
  }
}
